import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import uniqueId from 'lodash.uniqueid'
import { Trans } from 'react-i18next'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api'
import { Link } from '../'
import { defaultMapPinColor } from '../../constants'
import styles from './mapstyles'
import './index.css'

const containerStyle = {
  height: '500px',
  width: '100%',
}

const options = {
  fullscreenControl: false,
  mapTypeControl: false,
  rotateControl: false,
  scaleControl: false,
  streetViewControl: false,
  styles,
}

const defaultCloseFilter = () => {}

const Map = ({ markers, selectedRetailer={}, zoom = 10.5, closeFilter = defaultCloseFilter}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    id: 'google-map-script',
  })

  const [map, setMap] = useState(null)
  const [selectedMarker, setSelectedMarker] = useState(null)

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds()
      markers.forEach(marker => {
        bounds.extend({
          lat: marker.lat,
          lng: marker.lng,
        })
      })
      map.fitBounds(bounds)
    }
  }, [map, markers])

  useEffect(() => {
    closeFilter()
  }, [selectedMarker])

  const onLoad = useCallback((map) => {
    setMap(map)
  }, [])

  const handleMarkerClick = (location) => {
    setSelectedMarker(selectedMarker === location ? null : location)
  }

  return isLoaded ? (
    <GoogleMap
      defaultOptions={styles}
      mapContainerStyle={containerStyle}
      onClick={() => setSelectedMarker(null)}
      onLoad={onLoad}
      options={options}
      zoom={zoom}
    >
      {React.Children.toArray(
        markers?.filter(({ retailer }) => !selectedRetailer.id || (retailer?.id === selectedRetailer.id))
          .map(marker => (
            <Marker
              key={uniqueId()}
              onClick={() => handleMarkerClick(marker)}
              onLoad={icon => {
                const customIcon = (opts) => Object.assign({
                  anchor: new window.google.maps.Point(15, 30),
                  fillColor: marker.retailer?.color || defaultMapPinColor,
                  fillOpacity: 0.8,
                  path: 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z',
                  scale: 1,
                  strokeWeight: 0,
                }, opts)

                icon.setIcon(customIcon())
              }}
              position={{ lat: marker.lat, lng: marker.lng }}
            />
          ))
      )}

      {!!selectedMarker && (
        <InfoWindow
          position={{
            lat: selectedMarker.lat + 0.01,
            lng: selectedMarker.lng,
          }}
        >
          <div className="info-box">
            <h3>{selectedMarker.name}</h3>

            <p>{selectedMarker.details}</p>

            <hr className="separator" />

            <p className="route-plan">
              <Link
                externalLink={`https://www.google.com/maps/dir/?api=1&destination=${selectedMarker.lat},${selectedMarker.lng}`}
              >
                <Trans>directions</Trans>
              </Link>
            </p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  )
}

Map.propTypes = {
  center: PropTypes.object,
  closeFilter: PropTypes.func,
  directionText: PropTypes.string,
  markers: PropTypes.array,
  selectedRetailer: PropTypes.object,
  zoom: PropTypes.number,
}

export default Map
