import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'
import { useLoaderData, useSearchParams } from 'react-router-dom'
import { Carousel } from 'nuka-carousel'
import { Trans } from 'react-i18next'
import { Button, Card, Col, Row } from 'react-bootstrap'
import {
  BrandBar,
  Link,
  RetailerLocations,
  SEO,
} from '../../components'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { countryDetails } from '../../elements/shop/country-details'

import './index.scss'

import apis from '../../api'

const ShopPage = () => {
  const data = useLoaderData()

  const [searchParams, setSearchParams] = useSearchParams()
  const countryParam = searchParams.get('country') || null
  const selectedCountry = countryDetails[countryParam] || null
  const countrySlug = null

  const countryRef = useRef(null)
  const [brandImages, setBrandImages] = useState([])
  const [cardsForCountry, setCardsForCountry] = useState([])
  const [catalogue, setCatalogue] = useState([])

  useEffect(() => {
    if (!selectedCountry) return undefined

    const filterCards = () => {
      const filteredCards = data.deploymentCards
        .filter(card => card.attributes.Country?.Countries?.includes(selectedCountry.name))
        .sort((a, b) =>
          a.attributes.orderIncarousel > b.attributes.orderIncarousel ? 1 : -1,
        )
      setCardsForCountry(filteredCards)
    }

    const filterProductCatalogues = () => {
      const filteredProductCatalogue = data.productCatalogues.filter(
        cat => cat.attributes.Country?.Countries?.includes(selectedCountry.name),
      )

      setCatalogue(filteredProductCatalogue)
    }

    const getCountryShopPage = async () => {
      const { data } = await apis.strapiInstance(`/shop-page?populate[ShopPageBrandList][populate]=*&locale=${selectedCountry.key}`)

      if (data.data) {
        setBrandImages(data.data.attributes.ShopPageBrandList[0].ShopPageBrandLogos.data)
      }
    }

    getCountryShopPage()
    filterCards()
    filterProductCatalogues()

    setTimeout(() => scrollToDetails(), 500)
  }, [data.deploymentCards, data.productCatalogues, selectedCountry])

  useEffect(() => {
    const selectCountry = country => {
      if (country)
        setSearchParams({
          country,
        })
    }

    selectCountry(countrySlug)
  }, [countrySlug, setSearchParams])

  const scrollToDetails = () => {
    const refPosition = countryRef.current?.offsetTop

    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: refPosition - 20,
    })
  }

  return (
    <>
      <SEO title="Shop" />

      <div className="map-background">
        <div className="container-lg">
          <div className="country-selector-images">
            <div className="main-section-title select-country-title">
              <Trans>whereLoopAvailable</Trans>
            </div>

            <div className="custom-title">
              <h5><Trans>selectACountry</Trans></h5>
            </div>

            <div className="country-badges">
              {React.Children.toArray(
                Object.keys(countryDetails).map(countryKey => (
                  <Card className="country-badge-container">
                    <Link path={`/shop/?country=${countryKey}`}>
                      <Card.Body>
                        <div className="select-country-card-top">
                          <Card.Img
                            alt={`${countryKey}-img`}
                            className="country-badge-image"
                            src={countryDetails[countryKey].img}
                            style={{ width: '70px' }}
                          />
                          <Card.Title><Trans>{countryDetails[countryKey].name}</Trans></Card.Title>

                        </div>
                      </Card.Body>
                    </Link>
                  </Card>
                )),
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="not-available">
        <div className="not-available-text">
          &nbsp;<Trans>notAvailableInYourCountryYet</Trans>&nbsp;
        </div>
        <Link path="/waiting-list">
          <Button
            className="stay-tuned-button"
            variant="light"
          >
            <Trans>stayTuned</Trans>
          </Button>
        </Link>
      </div>

      <div>
        {selectedCountry && (
          <div className="container-lg selected-country-container">
            <div className="shop-page-subtitle" ref={countryRef}>
              <Trans
                values={{
                  country: selectedCountry?.nameWithPrefix,
                }}
              >
                whereToFind
              </Trans>
            </div>

            {cardsForCountry.length > 0 && (
              <Carousel
                autoplay={true}
                autoplayInterval={5000}
                className="activation-card-list"
                scrollDistance="slide"
                showArrows="hover"
                wrapAround
                wrapMode="wrap"
              >
                {React.Children.toArray(
                  (cardsForCountry || []).map(({ attributes }) => (
                    <Link
                      className="shop-card-link slider-card"
                      externalLink={attributes.UrlPath}
                      path={`/${attributes.UrlPath}`}
                    >
                      <div className="shop-details-card">
                        <div className="card-body">
                          {attributes.Flags?.includes('instore') && (
                            <div className="ribbon ribbon-top-right instore-flag"><span><Trans>inStore</Trans></span></div>
                          )}

                          {attributes.Flags?.includes('online') && (
                            <div className="ribbon ribbon-top-right online-flag"><span><Trans>online</Trans></span></div>
                          )}

                          {attributes.Flags?.includes('comingsoon') && (
                            <div className="ribbon ribbon-top-right coming-soon-flag"><span><Trans>comingSoon</Trans></span></div>
                          )}

                          <div className="card-image-container">
                            <img
                              alt={attributes.Logo.data.attributes.alternativeText}
                              className="card-img-top shop-card-image"
                              src={attributes.Logo?.data.attributes.url}
                            />
                          </div>

                          <Markdown className="shop-card-text card-text" rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                            {attributes.Description}
                          </Markdown>
                        </div>
                        <Card.Footer className="learn-more-button">
                          {attributes.ButtonText || 'Learn More'}
                        </Card.Footer>
                      </div>
                    </Link>
                  )),
                )}
              </Carousel>
            )}

            <RetailerLocations country={selectedCountry} />

            {React.Children.toArray(
              catalogue.map(({ attributes }) => (
                <Row>
                  <Col className="d-flex flex-column align-items-center mt-6">
                    {attributes.Title && (
                      <div className="shop-page-subtitle">{attributes.Title}</div>
                    )}
                    {attributes.URL && (
                      <Button
                        className="main-button mt-4 mx-auto"
                        href={attributes.URL}
                        rel="noreferrer noopener"
                        target="_blank"
                        variant="primary"
                      >
                        {attributes.ButtonText}
                      </Button>
                    )}
                  </Col>
                </Row>
              )),
            )}

            {brandImages.length > 0 && (
              <>
                <div className="shop-page-subtitle"><Trans>ourBrands</Trans></div>
                <div className="our-brands-text">
                  <Trans>brandsDescription</Trans>
                </div>
                <div className="our-brands-carousel">
                  <BrandBar data={brandImages} />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  )
}

ShopPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  pageContext: PropTypes.object,
}

export default ShopPage
