import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { FadeIn } from '../../../components'

const RetailerHeader = ({ data }) => {
  const isMobile = window.innerWidth <= 520
  const desktopImageUrl = data?.attributes?.HeaderBackgroundImage?.DesktopImage?.data?.attributes?.url || ''
  const mobileImageUrl = data?.attributes?.HeaderBackgroundImage?.MobileImage?.data?.attributes?.url || desktopImageUrl || ''

  const backgroundImageUrl = isMobile ? mobileImageUrl : desktopImageUrl

  if (isEmpty(backgroundImageUrl)) return null

  return (
    <>
      <FadeIn delay={800}>
        <div
          aria-label={data?.attributes?.HeaderBackgroundImage?.DesktopImage?.data?.attributes?.alternativeText || ''}
          className="retailer-header"
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: '100%',
          }}
        >
          <div className="container-lg">
            <FadeIn delay={1000} horizontal>
              <div className="retailer-header-info">
                <div className="header-logo-container">
                  <img
                    alt={data.attributes.BrandImage?.data.attributesalternativeText || ''}
                    className="header-logo"
                    src={data.attributes.BrandImage?.data.attributes.url}
                    style={{ height: 'auto', width: '100%' }}
                  />
                </div>

                <FadeIn delay={1500}>
                  <div>{data.attributes.HeaderTitle}</div>
                </FadeIn>
              </div>
            </FadeIn>
          </div>
        </div>
      </FadeIn>
    </>
  )}

RetailerHeader.propTypes = {
  data: PropTypes.object,
}

export default RetailerHeader
