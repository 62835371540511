import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { compact, uniqBy, uniqueId } from 'lodash'
import { Map } from '../'
import { defaultMapPinColor } from '../../constants'

import './index.css'

import apis from '../../api'

const countryMapping = {
  fr: 'fr',
  ja: 'jp',
}

const RetailerLocations = ({ country }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [brands, setBrands] = useState(undefined)
  const [retailerListOpen, setRetailerListOpen] = useState(false)
  const [selectedRetailer, setSelectedRetailer] = useState(undefined)

  const countryCode = countryMapping[country.key]

  useEffect(() => {
    const fetchBrandPages = async () => {
      const { data } = await apis.webappInstance.get('/bins', {
        params: {
          country_code: countryCode,
        },
      })
      setBrands(data)
      setIsLoading(false)
    }

    fetchBrandPages()
    resetRetailerSelection()

  }, [countryCode, country])

  const selectRetailer = (value) => {
    setSelectedRetailer(value)
    setRetailerListOpen(false)
  }

  const resetRetailerSelection = () => {
    setRetailerListOpen(false)
    setSelectedRetailer(undefined)
  }

  const retailerList = compact(uniqBy(brands, 'retailer.id').map(({ retailer }) => retailer))

  if (isLoading || !retailerList || retailerList.length === 0) return null

  return (
    <div>
      <div className="shop-page-subtitle mb-4">
        <Trans>availableLocations</Trans>
      </div>
      <div className="location-map-container">
        <div className="legend-container">
          <div className="retailer-selector-group">
            <div
              className="retailer-selector"
              onClick={() => setRetailerListOpen(!retailerListOpen)}
              value={selectedRetailer}
            >
              <div className="retailer-selector-opener">
                {!selectedRetailer || retailerListOpen ? (
                  <><Trans>selectRetailer</Trans></>
                ) : (
                  <>
                    <div
                      className="retailer-color-badge"
                      style={{
                        backgroundColor:
                          selectedRetailer.color || defaultMapPinColor,
                      }}
                    />
                    <div>{selectedRetailer.name}</div>
                  </>
                )}
                <div className={`retailer-selector-caret ${
                  retailerListOpen ? 'rotated' : ''
                }`}
                >
                </div>
              </div>
            </div>

            {retailerListOpen && (
              <div>
                <hr />
                {retailerList.map((retailer) => (
                  <div
                    className="retailer-selector"
                    key={uniqueId()}
                    onClick={() => selectRetailer(retailer)}
                  >
                    <div className="retailer-color-badge"
                      style={{
                        backgroundColor: retailer.color || defaultMapPinColor,
                      }}
                    />
                    <div>{retailer.name}</div>
                  </div>
                ))}
                <hr />
                <div
                  className="retailer-selector show-all-field"
                  onClick={resetRetailerSelection}
                >
                  <Trans>reset</Trans>
                </div>
              </div>
            )}
          </div>
        </div>

        <Map closeFilter={() => setRetailerListOpen(false)} markers={brands} selectedRetailer={selectedRetailer} />
      </div>
    </div>
  )
}

RetailerLocations.propTypes = {
  country: PropTypes.object,
}

export default RetailerLocations
