import React, { useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Dropdown, Nav, Navbar } from 'react-bootstrap'
import loopLogo from '../../images/logo.png'

import activeLanguages from '../../constants/languages'

import './index.css'

const Header = () => {
  const { urlLang } = useParams()
  const { i18n } = useTranslation()
  const { language } = i18n
  const urlPrefix = (urlLang && Object.keys(activeLanguages).includes(urlLang)) ? `/${urlLang}/` : '/'

  const [navExpanded, setNavExpanded] = useState(false)

  const setNavbar = expanded => {
    setNavExpanded(expanded)
  }

  const closeNav = () => {
    if (navExpanded) setNavExpanded(false)
  }

  const externalLink = {
    en: '/app/en-US/',
    fr: '/app/fr-FR/',
    ja: '/app/ja-JP/',
  }

  return (
    <Navbar
      bg="light"
      className="loop-nav"
      expand="lg"
      expanded={navExpanded}
      fixed="top"
      onToggle={setNavbar}
    >
      <Navbar.Brand>
        <NavLink onClick={closeNav} to={urlPrefix}>
          <img alt="Loop logo" className="navbar-logo" src={loopLogo} />
        </NavLink>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto mb-0">
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? 'selectedMenu' : ''}`}
            onClick={closeNav}
            to={`${urlPrefix}shop`}
          >
            <span><Trans>header.shop</Trans></span>
          </NavLink>
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? 'selectedMenu' : ''}`}
            onClick={closeNav}
            to={`${urlPrefix}purpose`}
          >
            <span><Trans>header.purpose</Trans></span>
          </NavLink>
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? 'selectedMenu' : ''}`}
            onClick={closeNav}
            to={`${urlPrefix}partners`}
          >
            <span><Trans>header.partners</Trans></span>
          </NavLink>
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? 'selectedMenu' : ''}`}
            onClick={closeNav}
            to={`${urlPrefix}join`}
          >
            <span><Trans>header.joinUs</Trans></span>
          </NavLink>
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? 'selectedMenu' : ''}`}
            onClick={closeNav}
            to={`${urlPrefix}press`}
          >
            <span><Trans>header.press</Trans></span>
          </NavLink>

          <NavLink
            className="nav-link nav-link-below-720"
            to={externalLink[language]}
          >
            <Button className="nav-button mb-md-0 mb-4" size="md" variant="primary">
              <Trans>header.downloadApp</Trans>
            </Button>
          </NavLink>

          <LanguageSelector className="nav-link-below-720 lng-selector" />
        </Nav>

        <NavLink
          className="nav-link nav-link-above-720 pl-sm-0 pr-sm-0 mr-lg-4"
          to={externalLink[language]}
        >
          <Button className="nav-button" size="sm" variant="primary">
            <Trans>header.downloadApp</Trans>
          </Button>
        </NavLink>

        <LanguageSelector className="nav-link-above-720 pl-sm-0  pr-sm-0 mr-lg-4" />

      </Navbar.Collapse>
    </Navbar>
  )
}

const LanguageSelector = (props) => {
  const navigate = useNavigate()
  const { urlLang } = useParams()
  const { i18n } = useTranslation()
  const { changeLanguage, language } = i18n

  const setLanguage = (lang) => {
    changeLanguage(lang)
    window?.OneTrust?.changeLanguage(lang.split('-')[0])

    document.documentElement.lang = lang

    const newUrl = Object.keys(activeLanguages).includes(urlLang)
      ? window.location.pathname.replace(urlLang, lang)
      : `/${lang}${window.location.pathname}`

    navigate(newUrl)
  }

  return (
    <Dropdown {...props}>
      <Dropdown.Toggle className="nav-button btn btn-primary btn-sm selector-toggle" variant="primary">
        <img alt="active-flag" className="selected-language-image" src={activeLanguages[language].img} /> {activeLanguages[language].name}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setLanguage('en')}>
          <img alt="en-flag" className="language-selector-image" src={activeLanguages.en.img} /> English
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLanguage('fr')}>
          <img alt="fr-flag" className="language-selector-image" src={activeLanguages.fr.img} /> Français
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLanguage('ja')}>
          <img alt="ja-flag" className="language-selector-image" src={activeLanguages.ja.img} /> 日本語
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Header
